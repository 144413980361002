@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Archivo SemiExpanded";
  src: url("../static/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body{
  font-family: 'Archivo SemiExpanded';
}
.site-container{
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px;
  width: 100%;
}
.header-section{

  margin-bottom: 40px;

  .header-link{
    padding: 10px 20px;
    background-color: #FFBE06;
    color: #182C56;
    border: 1px solid #182C56;
    border-radius: 6px;
    text-decoration: none;
    letter-spacing: -0.015em;

    &:hover{
      text-decoration: none;
      color: #182C56;
    }
  }

  .logo{
    height: 26px;
    width: fit-content;
  }
}

.title{
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: -0.015em;
  color: #182C56;
  margin-bottom: 10px;
}
.sub-title{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
  color: #4263A6;
  margin-bottom: 30px;
}
.table-container{
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  overflow: hidden;
}
.table-row{
  width: 100%;
  .status-container{
    white-space: nowrap;
  }
  &.header{
    th{
      &:first-child{
        padding-left: 20px;
      }
      background-color: #F1F1F1;
    height: 42px;
    font-weight: 600;
    font-size: 12px;
    color: #AEAEAE;
    }
    background-color: #F1F1F1;
    height: 42px;
    font-weight: 600;
    font-size: 12px;
    color: #AEAEAE;
  }

  &.detail{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #182C56;

    & > td{
      padding: 20px 0;
      border-bottom: 1px solid #E8E8E8;
      .image-container{
        width: 25px;
        margin-right: 8px;

        img{
          max-width: 100%;
        }
      }
      &:first-child{
        padding-left: 20px;
      }
    }


    &:last-child{
      & > div{
        border-bottom: 0;
      }
    }

    .api-title{
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 3px;
    }
    .api-url{
      color: #AEAEAE;
    }

    .doc-link{
      color: #182C56;
      &:hover{
        color: #182C56;
      }
    }


    .status-container{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      .status-circle{
        height: 18px;
        width: 18px;
        border-radius: 50%;
        &.minor{
          background-color: orange;
        }
        &.major{
          background-color: red;
        }
        &.operational{
          background-color: #00B67A;
        }

        &.active{
          background-color: #00B67A;
        }
      }
    }
  }
  .propchain-tooltip {
    cursor: help;
    position: relative;
    &.left {

        &:after {
            top: -5px;
            right: 105%;
            transform: translateY(-50%);
        }
    }

    &.right {

        &:after {
            top: -5px;
            left: 105%;
            transform: translateY(-50%);
        }
    }

    &.top {

        &:after {
            width: 150px;
            bottom: 100%;
            left: 50%;
            margin-left: -125px;
        }
    }

    &.bottom {

        &:after {
            width: 200px;
            top: 100%;
            left: 50%;
            margin-left: -125px;
        }
    }

    &.left-and-bottom {

        &:after {
            width: 200px;
            top: 100%;
            left: 50%;
            margin-left: -125px;
        }
    }

    &:after {
        background-color: #e3e9f7;
        color: #00143e;
        text-align: center;
        padding: 8px;
        width: 250px;
        border-radius: 10px;
        font-size: 14px !important;
        line-height: 20px;
        font-weight: 400;
        content: attr(data-title);
        white-space: normal;
        opacity: 0;
        position: absolute;
        visibility: hidden;
        // transition: opacity 400ms ease-in;
        z-index: -100;
    }

    &:hover:after,
    &:focus:after {
        visibility: visible;
        opacity: 1;
        z-index: 100;
    }
}
}

.table-status-panel {
  width: 100%;
}